<script setup>
import { toRefs, computed } from "vue";

import { JCol } from "media-flow-ui";
import { ColumnStyleModel } from "~/models/layout/Column";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  rowOrder: {
    type: Number,
    required: false,
    default: null
  },
});

const { data } = toRefs(props);

const style = computed(() => {
  const s = new ColumnStyleModel();
  s.fromData(data.value.style);
  return s;
});
</script>


<template>
  <JCol :class="[data.id, data?.class]" :id="data.id" :key="data.id" :columns="data.props.columns"
    :orientation="data.style.orientation || 'vertical'" :style="style">
    <template v-if="data.children && data.children.length > 0">
      <Identifier v-for="component in data.children" :component="component" :key="component.id" :rowOrder="rowOrder" />
    </template>
  </JCol>
</template>
